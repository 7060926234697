var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-card',[_c('template',{slot:"title"},[_c('strong',[_vm._v("Saldo Tabungan")]),_c('a-date-picker',{staticClass:"ml-4",staticStyle:{"width":"150px"},attrs:{"allowClear":false,"format":"DD-MM-YYYY"},on:{"change":_vm.filterDate},model:{value:(_vm.tgl1),callback:function ($$v) {_vm.tgl1=$$v},expression:"tgl1"}}),_c('a-button',{staticClass:"btn btn-outline-warning ml-2 float-right",attrs:{"type":"button"},on:{"click":_vm.generateLaporanHarian}},[_vm._v(" Print Preview ")]),_c('a-button',{staticClass:"btn btn-outline-success float-right",on:{"click":_vm.exportExcel}},[_vm._v("Export Data")]),_c('a-button',{staticClass:"btn btn-outline-success ml-2",on:{"click":function($event){return _vm.getAllData(true)}}},[_vm._v("Refresh Data")])],1),_c('a-table',{attrs:{"columns":_vm.columns,"dataSource":_vm.data,"pagination":{
        hideOnSinglePage: true,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} items`,
        pageSizeOptions: ['10', '20', '30'],
      },"size":"small"},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.title}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
              .toString()
              .split(new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i'))),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_vm._v(" "+_vm._s(text)+" ")]]}},{key:"name",fn:function(text){return _c('a',{attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(text))])}},{key:"tags",fn:function(tags){return _c('span',{},_vm._l((tags),function(tag){return _c('a-tag',{key:tag,attrs:{"color":"blue"}},[_vm._v(_vm._s(tag))])}),1)}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a',{staticClass:"text-warning",on:{"click":function($event){return _vm.sendToEdit(record)}}},[_c('a-icon',{attrs:{"type":"edit"}})],1),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{staticClass:"text-danger",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showDeleteConfirm(record)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)}},{key:"tgl",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatDate(text))+" ")])}},{key:"boolean",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatBoolean(text))+" ")])}},{key:"sudah_cair",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(text === "0" ? "Belum Cair" : text === "1" ? "Sudah Cair" : text ? "Sudah Cair" : !text ? "Belum Cair" : text)+" ")])}},{key:"formatCurrency",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatCurrency(text))+" ")])}}])},[_c('span',{attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('a-icon',{attrs:{"type":"smile-o"}}),_vm._v(" Kode")],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }